var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.day
        ? _c(
            "accordion",
            {
              staticClass: "day has-hidden-arrow is-concept",
              class: { "is-empty": _vm.placeholders.length === 0 },
              attrs: {
                isCollapsible: false,
                isOpen:
                  _vm.placeholders.length > 0 ||
                  !!_vm.createNew ||
                  _vm.showEmptyRow,
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("header", [
                  _c("div", { staticClass: "tags mb-0 mr-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "is-truncated",
                        on: { click: _vm.toggleIsHidden },
                      },
                      [
                        _vm.day.isHidden
                          ? _c("span", { staticClass: "tag is-red mb-0" }, [
                              _vm._v("INAKTIV"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.day.isHidden
                          ? _c("span", { staticClass: "tag is-white mb-0" }, [
                              _vm._v("AKTIV"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.dayCount ? "Tag " + _vm.dayCount + " - " : ""
                      ) +
                        _vm._s(_vm.day.name) +
                        _vm._s(
                          _vm.day.distance
                            ? " (" + _vm.day.distance + " Km)"
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "day-action-buttons" },
                    [
                      _vm.countOrderConcepts - 1
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "mr-1",
                                staticStyle: {
                                  "font-size": "10px",
                                  "text-transform": "none",
                                },
                              },
                              [_vm._v("Vorsicht beim Editieren - in")]
                            ),
                            _vm._v(" "),
                            _c(
                              "popover",
                              {
                                attrs: { trigger: "hover" },
                                on: { show: _vm.getOrderConceptsInfo },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "popover",
                                      fn: function (slotProps) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "todo__popover" },
                                            [
                                              !_vm.orderConceptsInfo
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("loading", {
                                                        staticClass: "is-small",
                                                        attrs: {
                                                          "text-after": "",
                                                          "text-in-front": "",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "ul",
                                                    {
                                                      staticClass: "icon-list",
                                                    },
                                                    _vm._l(
                                                      _vm.orderConceptsInfo,
                                                      function (concept) {
                                                        return _c("li", [
                                                          _vm._v(
                                                            "\n                                            #" +
                                                              _vm._s(
                                                                concept.id
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                concept.name
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                concept.years.join(
                                                                  " | "
                                                                )
                                                                  ? " (Katalog: " +
                                                                      concept.years.join(
                                                                        " | "
                                                                      ) +
                                                                      ")"
                                                                  : ""
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ])
                                                      }
                                                    ),
                                                    0
                                                  ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  174597355
                                ),
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button mr-1",
                                    staticStyle: {
                                      "font-size": "10px",
                                      height: "20px",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.countOrderConcepts))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "10px",
                                  "text-transform": "none",
                                },
                              },
                              [_vm._v(" Konzepten")]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLoading.initialize || _vm.isLoading.deleting
                        ? _c("i", { staticClass: "material-icons icon-spin" }, [
                            _vm._v("refresh"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "simple-menu",
                        {
                          attrs: {
                            material: "",
                            icon: "more_vert",
                            title: "Menu",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("hotel")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Hotel"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("other")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Zusatzleistung"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("ferry")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Fähre"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("train")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Zug"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("airline")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Flug"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("route")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Routenstichpunkt"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.create("free")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("add"),
                              ]),
                              _vm._v(" Freitext"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("openDayForm", _vm.day)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ]),
                              _vm._v(" bearbeiten"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("copyDay", _vm.day)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("content_copy"),
                              ]),
                              _vm._v(" Tag kopieren"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openDay(_vm.day)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("link"),
                              ]),
                              _vm._v("zum Tageskonzept"),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.dayCount
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("deleteDay")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("delete"),
                                  ]),
                                  _vm._v(" löschen"),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "content" },
                [
                  _vm.placeholders.length > 0 || !!_vm.createNew
                    ? [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              options: { disabled: _vm.isDraggableDisabled },
                            },
                            model: {
                              value: _vm.placeholders,
                              callback: function ($$v) {
                                _vm.placeholders = $$v
                              },
                              expression: "placeholders",
                            },
                          },
                          [
                            _vm._l(_vm.placeholders, function (placeholder) {
                              return [
                                _vm.editMode ===
                                placeholder.placeholderType +
                                  "-" +
                                  placeholder.id
                                  ? _c(placeholder.placeholderType + "-form", {
                                      key: placeholder.sort,
                                      tag: "component",
                                      attrs: {
                                        placeholder: placeholder,
                                        isLoading: _vm.isLoading.saving,
                                        options: _vm.options,
                                      },
                                      on: {
                                        save: _vm.savePlaceholder,
                                        cancel: function ($event) {
                                          _vm.editMode = ""
                                        },
                                      },
                                    })
                                  : _c(
                                      placeholder.placeholderType + "-view",
                                      {
                                        key: placeholder.sort,
                                        tag: "component",
                                        staticClass: "block--draggable",
                                        class: {
                                          "is-selected":
                                            _vm.isSelected(placeholder),
                                        },
                                        attrs: {
                                          placeholder: placeholder,
                                          isRouteView: _vm.isRouteView,
                                          isConcept: "",
                                          showDuration: false,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.$store.commit(
                                              "togglePlaceholder",
                                              placeholder
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "simple-menu",
                                          {
                                            attrs: {
                                              material: "",
                                              icon: "more_vert",
                                              title: "Menu",
                                            },
                                          },
                                          [
                                            placeholder.placeholderType !==
                                            "route"
                                              ? _c(
                                                  "button",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openConceptProviderForm(
                                                          {
                                                            providers:
                                                              placeholder.providers,
                                                            placeholder:
                                                              placeholder,
                                                            id: placeholder.id,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons",
                                                      },
                                                      [_vm._v("add")]
                                                    ),
                                                    _vm._v(
                                                      " Leistungsträger auswählen"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.editPlaceholder(
                                                      placeholder
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("mode_edit")]
                                                ),
                                                _vm._v(" Bearbeiten"),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-delete",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.deletePlaceholder(
                                                      {
                                                        key: _vm.types[
                                                          placeholder
                                                            .placeholderType
                                                        ],
                                                        id: placeholder.id,
                                                      }
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("delete")]
                                                ),
                                                _vm._v(" Löschen"),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.crudLink(placeholder)
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.crudLink(
                                                    placeholder
                                                  )
                                                    ? _vm.crudLink(placeholder)
                                                    : "#",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("launch")]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("launch")]
                                            ),
                                      ],
                                      1
                                    ),
                              ]
                            }),
                            _vm._v(" "),
                            _c("concept-provider-form", {
                              ref: "conceptProviderForm",
                              attrs: { options: [] },
                            }),
                            _vm._v(" "),
                            _vm.createNew
                              ? _c(_vm.createNew + "-form", {
                                  tag: "component",
                                  attrs: {
                                    slot: "footer",
                                    isLoading: _vm.isLoading.saving,
                                    options: _vm.options,
                                  },
                                  on: {
                                    save: _vm.saveNewPlaceholder,
                                    cancel: function ($event) {
                                      _vm.createNew = ""
                                    },
                                  },
                                  slot: "footer",
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEmptyRow
                    ? _c("div", { staticClass: "placeholder is-empty" }, [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n                    Bitte\n                    "
                            ),
                            _c("simple-menu", [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("hotel")
                                    },
                                  },
                                },
                                [_vm._v("Hotel")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("other")
                                    },
                                  },
                                },
                                [_vm._v("Zusatzleistung")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("ferry")
                                    },
                                  },
                                },
                                [_vm._v("Fähre")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("train")
                                    },
                                  },
                                },
                                [_vm._v("Zug")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("airline")
                                    },
                                  },
                                },
                                [_vm._v("Flug")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("route")
                                    },
                                  },
                                },
                                [_vm._v("Routenstichpunkt")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.create("free")
                                    },
                                  },
                                },
                                [_vm._v("Freitext")]
                              ),
                            ]),
                            _vm._v(
                              "\n                    drücken um ein neues Tageselement hinzuzufügen\n                "
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          )
        : _c("loading"),
      _vm._v(" "),
      _vm.createNew || _vm.editMode
        ? _c("div", { staticClass: "is-overlay" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }