<template>
    <modal-form
        editTitle="Aufgabe bearbeiten"
        createTitle="Neue Aufgabe erstellen"
        v-on:closeModal="visible = false"
        v-on:update="updateTodo"
        v-on:create="createTodo"
    >
        <div class="loading-container" v-if="isLoading">
            <loading />
        </div>
        <table>
            <tr>
                <td colspan="2">
                    <h2>Allgemeines</h2>
                </td>
            </tr>
            <tr>
                <th>
                    <label for="TodoType" class="label">Kategorie:</label>
                </th>
                <td>
                    <toggle-switch v-model="form.todoType">
                        <option value="general">Aufgabe</option>
                        <option value="visit">Kundenbesuch</option>
                        <option value="phone">Telefontermin</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="showOrder">
                <th>
                    <label for="order" class="label">Reise (optional):</label>
                </th>
                <td>
                    <order-select v-model="form.order" />
                </td>
            </tr>

            <template v-if="hasOrder">
                <tr>
                    <th>
                        <label for="deadlineType" class="label">Art der Eingabe:</label>
                    </th>
                    <td>
                        <div class="buttons has-addons toggle-switch">
                            <button
                                class="button"
                                v-bind:class="{'is-primary': deadlineType === 'dueDate'}"
                                v-on:click.prevent="deadlineType = 'dueDate'"
                            >Datum</button>
                            <button
                                class="button"
                                v-bind:class="{'is-primary': deadlineType === 'deadlineDays'}"
                                v-on:click.prevent="deadlineType = 'deadlineDays'"
                            >Tage vor Reisebeginn</button>
                        </div>
                    </td>
                </tr>

                <tr v-if="deadlineType === 'deadlineDays'">
                    <th>
                        <label for="deadlineDays" class="label">Tage vor Reisebeginn:</label>
                    </th>
                    <td>
                        <input type="text" class="input is-small" v-model.number="deadlineDays">
                    </td>
                </tr>
            </template>

            <tr v-if="!hasOrder || deadlineType === 'dueDate'">
                <th>
                    <label for="dueDate" class="label">Deadline:</label>
                </th>
                <td>
                    <input-date
                        v-model="form.dueDate"
                        ref="dueDate"
                    />
                </td>
            </tr>
            <tr>
                <th>
                    <label for="status" class="label">Status:</label>
                </th>
                <td>
                    <div class="select">
                        <select v-model="form.status">
                            <option
                                v-for="status in options.status"
                                v-bind:value="status.key"
                                v-bind:key="status.key"
                            >{{status.value}}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <label for="name" class="label">Aufgabe:</label>
                </th>
                <td>
                    <multiselect
                        v-model="form.name"
                        v-bind:options="form.type === 'internal' ? options.internalNames : options.externalNames"
                        v-bind:taggable="true"
                        v-on:tag="addName"
                        v-on:search-change="searchName"
                        v-on:close="closeName"
                        :internalSearch="false"
                        :clearOnSelect="false"
                        :preseveSearch="true"
                    >
                        <span slot="caret"></span>
                    </multiselect>
                </td>
            </tr>
            <tr>
                <th>
                    <label for="type" class="label">Art:</label>
                </th>
                <td>
                    <toggle-switch v-model="form.type">
                        <option value="external">Strenge Deadline</option>
                        <option value="internal">Weiche Deadline</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr>
                <th class="is-top">
                    <label for="description" class="label">Beschreibung:</label>
                </th>
                <td>
                    <textarea class="textarea" v-model="form.description"></textarea>
                </td>
            </tr>
            <tr>
                <th>
                    <label for="user" class="label">User:</label>
                </th>
                <td>
                    <user-select v-model="form.user" />
                </td>
            </tr>
            <tr>
                <th>
                    <label for="tags" class="label">Tags:</label>
                </th>
                <td>
                    <multiselect
                        v-model="form.tags"
                        v-bind:options="options.predefinedTags"
                        v-bind:taggable="true"
                        v-on:tag="addTag"
                        :custom-label="tagLabel"
                        multiple
                    >
                        <span slot="caret"></span>
                    </multiselect>
                </td>
            </tr>
            <template v-if="hasOrder">
                <tr>
                    <td colspan="2">
                        <h2>Gültig für folgende Stati</h2>
                    </td>
                </tr>
                <tr>

                    <td class="">
                        <label class="label">
                            <button
                                class="tag is-blue-dark" style="width:50%"
                                @click.prevent="toggleAllCheckboxes('all')"
                            >alle </button>
                        </label>
                    </td>
                </tr>

                <tr >
                    <td style="vertical-align: middle" class="pb-0 pt-1">
                        <label class="label">
                            <button
                                class="tag is-green" style="width:50%"
                                @click.prevent="toggleAllCheckboxes('positive')"
                            >positive </button>
                        </label>
                    </td>
                    <td class="pt-1">
                        <div class="control">
                            <div class="checkboxes columns">
                                <div class="column is-one-third" v-for="status in options.positiveValidFor" v-bind:key="status.value">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="form.validForStatuses" v-bind:value="{ status: status.key }">
                                        {{ status.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr style="border-top: 1px solid #ddd">
                    <td style="vertical-align: middle" class="pb-0 pt-2">
                        <label class="label">
                            <button
                                class="tag is-red" style="width:50%"
                                @click.prevent="toggleAllCheckboxes('negative')"
                            >negative </button>
                        </label>
                    </td>
                    <td class="pt-2">
                        <div class="control">
                            <div class="checkboxes columns">
                                <div class="column is-one-third" v-for="status in options.negativeValidFor" v-bind:key="status.value">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="form.validForStatuses" v-bind:value="{ status: status.key }">
                                        {{ status.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td></td>

                </tr>
            </template>




        </table>
    </modal-form>
</template>



<script>
import moment                                from 'moment';
import has                                   from 'lodash/has';

import Loading                               from '@components/Loading';
import { notifySuccess, notifyError }        from '@components/Notification';
import ModalForm                             from '@components/ModalForm';
import {addTodo, editTodo, getAxios, getOrders} from '@api';
import { dateISOtoView, dayMonth, fullYear, currentUser } from '@utilities/functions';
import { store }                             from '@orders/store';
import ToggleSwitch                          from './ToggleSwitch';

import UserSelect                            from './UserSelect';
import Multiselect                           from './Multiselect';
import InputDate                             from './InputDate';
import OrderSelect                           from './OrderSelect';
import ColorSwitch from "@components/form/ColorSwitch.vue";


const userObject = currentUser();


export default {
    store,

    props: {
        showOrder: {
            type: Boolean,
            default: false
        }
    },

    created: function () {
        this.$store.dispatch('fetchTodosOptions');
    },

    data: function () {
        return {
            isLoading: false,
            visible: false,
            nameSearch: '',
            deadlineDays: '',
            deadlineType: 'dueDate',
            buttonClicked: {
                'all': false,
                'positive': false,
                'negative': false
            },

            form: {},
            emptyForm: {
                description: "",
                name: "",
                tags: [],
                type: 'external',
                todoType: 'general',
                status: 'open',
                user: userObject,
                order: null,
                dueDate: null,
                validForStatuses: []
            },

            orders: []
        }
    },

    computed: {
        today: function () {
            return dateISOtoView((new Date()).toISOString()) + " 00:00:00";
        },

        editMode: function () {
            return has(this.form, 'id');
        },

        formData: function () {
            return Object.assign({}, {
                category: 'task',
                description: this.form.description,
                name: this.form.name,
                tags: this.form.tags ? this.form.tags.map(tag => {
                    return (typeof tag === 'string' ? tag : tag.name)
                }) : null,
                type: this.form.type,
                todoType: this.form.todoType,
                status: this.form.status,
                ...this.form.order && {
                    order: typeof this.form.order === 'object'
                        ? `/api/orders/${this.form.order.id}`
                        : this.form.order
                },
                ...this.form.user && {
                    user: typeof this.form.user === 'object'
                        ? `/api/users/${this.form.user.id}`
                        : this.form.user
                },
                dueDate: this.form.dueDate && this.form.dueDate.length < 11? this.form.dueDate + ' 00:00:00' : this.form.dueDate ,
                validForStatuses: this.form.validForStatuses,

                ...(!!this.form.request && { request: this.form.request }),
            });
        },

        options: function () {
            return this.$store.state.todosOptions;
        },

        hasOrder: function () {
            return !!this.form.order;
        },

        formOrderDueDate() {
            return (!!this.form.order && this.form.order.id), this.deadlineDays, Date.now();
        }
    },

    methods: {
        tagLabel: function (tag) {
            return (typeof tag === 'string' ? tag : tag.name)
        },

        open: function (data = null){
            if (data !== null) {

                new Promise((resolve, reject) => {
                    if(!data.validForStatuses) {
                        getAxios('todos/' + data.id).then(response => {
                            resolve(response.data);
                        })
                    } else {
                        resolve(data)
                    }
                }).then(response => {

                    data = response; 

                    const {
                        description, name, tags, type, todoType, status, dueDate,
                        id, order, request, validForStatuses = [], user = null
                    } = data;

                    this.deadlineType = 'dueDate';
                    this.deadlineDays = '';

                    this.form = {
                        ...this.emptyForm,
                        ...(!!id && {id}),
                        ...(!!description && {description}),
                        ...(!!name && {name}),
                        ...(!!tags && {tags : tags.map(tag => {
                                return {
                                    key: tag.substring(0, 2) + (+new Date()),
                                    name: tag
                                }
                            })}),
                        ...(!!type && {type}),
                        ...(!!todoType && {todoType}),
                        ...(!!status && {status}),
                        ...(!!user && {user}),
                        ...(!!order && {order}),
                        ...(!!dueDate && {dueDate}),
                        ...(!!validForStatuses && { validForStatuses : validForStatuses.map(validFor => {
                                return {
                                    status: validFor.status
                                }
                            })}),
                        ...(!!order && {order}),
                        ...(!!request && {request}),
                    };

                    if (!!name) {
                        this.addName(name);
                    }

                    if (!!this.$refs.dueDate) {
                        this.$refs.dueDate.datepicker.setDate(dueDate)
                    }
                });


            } else {
                this.form = Object.assign({}, this.emptyForm);
            }

            this.buttonClicked = {
                'all': false,
                'positive': false,
                'negative': false
            }


            this.allStatuses = false;
            this.visible = true;
            this.isLoading = !!data.isLoading;
        },

        close: function () {
            this.visible = false;
            this.isLoading = false;
        },

        updateTodo: function () {
            editTodo({id: this.form.id, data: this.formData}, {
                _groups: ['contact_read']
            }).then(response => {
                this.visible = false;
                this.$store.commit('updateTodo', response.data)
                this.onSuccess();
            }).catch(this.onError)

            /*if (!!this.form.request) {
                const requestDeadline = {
                    deadline: this.form.status === 'done' ? null : moment(this.form.dueDate, 'DD.MM.YYYY').format('DD.MM.YYYY 00:00:00'),
                    doneDate: this.form.status === 'done' ? this.today : null
                };

                axios.put(this.form.request, {
                    requestDeadline
                }).then(response => {
                    const request = response.data;
                    this.$store.commit('updateRequestDeadline', {requestID: request.id, ...request.requestDeadline})
                })
            }*/
        },

        createTodo: function () {
            addTodo(this.formData, "?_groups[]=order_list_read").then(response => {
                this.visible = false;
                this.$store.commit('addTodo', response.data)
                this.onSuccess();
            }).catch(this.onError)
        },

        onSuccess: function () {
            notifySuccess('Die Aufgabe wurde hinzugefügt!');
        },

        onError: function () {
            notifyError('Die Aufgabe konnte nicht hinzugefügt werden! Server Error!');
        },

        toggleAllCheckboxes: function (type) {
            let options = this.options.validFor;
            if(type === 'positive') {
                options = this.options.positiveValidFor;
            } else if(type === 'negative') {
                options = this.options.negativeValidFor;
            }
            if (!this.buttonClicked[type]) {
                this.form.validForStatuses = options.map(validFor => {
                    return {
                        status: validFor.key
                    }
                })
                this.buttonClicked[type] = true;
            } else {
                this.form.validForStatuses = [];
                this.buttonClicked[type] = false;
            }
        },

        addTag (newTag) {
            if (newTag !== "") {
                if (!this.form.tags.includes(newTag)) {
                    this.form.tags.push(newTag)
                }

                if (!this.options.predefinedTags.includes(newTag)) {
                    this.$store.commit('updateTodosOptions', {
                        predefinedTags: [...this.options.predefinedTags, newTag]
                    })
                }
            }
        },

        addName (newName) {
            if (newName !== "") {
                this.form.name = newName;

                if (!this.options.externalNames.includes(newName) && this.form.type === 'external') {
                    this.$store.commit('updateTodosOptions', {
                        externalNames: [...this.options.externalNames, newName],
                    })
                }

                if (!this.options.internalNames.includes(newName) && this.form.type === 'internal') {
                    this.$store.commit('updateTodosOptions', {
                        internalNames: [...this.options.internalNames, newName],
                    })
                }
            }
        },

        searchName: function (name) {
            this.nameSearch = name;
        },

        closeName: function () {
            this.addName(this.nameSearch)
        },

        orderLabel: function (order) {
            return '# ' + order.orderNumber +
                    ' - ' + order.client.companyName +
                    ' - ' + dayMonth(order.startAt) + ' - ' + fullYear(order.endAt);
        }
    },

    watch: {
        formOrderDueDate: function (newValue, oldValue) {
            if (!!this.form.order && this.deadlineDays !== "") {
                const newDate = moment(this.form.order.startAt, 'DD.MM.YYYY')
                    .subtract(this.deadlineDays, 'day')
                    .format('DD.MM.YYYY');

                this.form.dueDate = newDate;
            }
        },

        'form.dueDate': function (newValue, oldValue) {
            if (!!this.form.order && !!newValue) {
                const days = moment(this.form.order.startAt, 'DD.MM.YYYY')
                    .diff(moment(newValue, 'DD.MM.YYYY'), 'days');

                if (days !== this.deadlineDays) {
                    this.deadlineDays = days;
                }
            }
        }
    },

    components: {
        ColorSwitch,
        Loading,
        ModalForm,
        InputDate,
        Multiselect,
        UserSelect,
        OrderSelect,
        ToggleSwitch
    }
}
</script>
