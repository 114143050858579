import Vue from 'vue'
import Tooltip from 'v-tooltip';
import Vuex from 'vuex';
import VueTour from 'vue-tour';
import axios from 'axios';
import bugsnagVue from '@bugsnag/plugin-vue';
import VueFlags from "@growthbunker/vueflags";
import '../scss/style';

import { authHeader } from '@utilities/functions';
import BugsnagClient from '@utilities/bugsnag';
import Layout from '@root/layout';
import Tour from '@components/Tour';
import GmapVue from 'gmap-vue'


global.Vue = Vue;
Vue.use(Vuex);
Vue.use(Layout);
Vue.use(Tooltip);
Vue.use(VueTour);
Vue.use(VueFlags, {iconPath: '/assets/img/flags/',})


Vue.component('tour', Tour);

//BugsnagClient.use(bugsnagVue, Vue);

// AUTH
axios.defaults.headers.common = { ...axios.defaults.headers.common, ...authHeader() };
if(__ENV__ === 'development'){
    //axios.defaults.baseURL = 'https://test-travelize.buspaket.de'
}

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyA0htQK3QZ9L4hT08dXeyvRgLiazG8ci3w',
        // [OPTIONAL] This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)
        libraries: 'places',
    },

    // [OPTIONAL] If you intend to programmatically custom event listener code
    // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    // you might need to turn this on.
    autoBindAllEvents: false,

    // [OPTIONAL] If you want to manually install components, e.g.
    // import {GmapMarker} from 'gmap-vue/src/components/marker'
    // Vue.component('GmapMarker', GmapMarker)
    // then set installComponents to 'false'.
    // If you want to automatically install all the components this property must be set to 'true':
    installComponents: true,

    // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
    dynamicLoad: false,
})

import { components } from "gmap-vue";
const { Cluster } = components;

Vue.component('GmapCluster', Cluster);
